import seventhfbreview from "../../../img/seven/seventhfbreview.png";

function Right() {
  return (
    <div className="attention-two-right">
      <a
        href="https://www.facebook.com/share/p/nZ33dK22CsorqkDd/"
        target="__blank"
      >
        <div className="card text-center mt-5 p-0">
          <div className="card-body">
            <h2 className="card-title" style={{ color: "#c6923a" }}>
              <i className="fa fa-heart" aria-hidden="true"></i> &nbsp; Not
              everything is about money
            </h2>

            <h3 className="card-text fw-light text-end">
              Beyond-the-Role Contributions
            </h3>
            <p style={{ color: "#c6923a" }} className="text-end">
              {" "}
              <i className="fa fa-facebook" aria-hidden="true"></i> Check the
              traction
            </p>

            <div className="card-bottom-img">
              <img src={seventhfbreview} alt="facebook-review-for-gtaf.org" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
export default Right;
