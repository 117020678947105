import React from "react";
import Header from "./components/Header";
import { ProgressBar } from "@nadfri/react-scroll-progress-bar";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StateProvider from "./Context/StateProvider";
import FixedFooter from "./components/FixedFooter";
import Blog from "./components/blogs/Blog";
import BlogList from "./components/blogs/BlogList";
import ProjectList from "./components/projects/ProjectList";
import ProjectDetails from "./components/projects/ProjectDetails";
import ProductDetails from "./components/products/ProductDetails";
import ProductList from "./components/products/ProductList";
import Navbar from "./components/Navbar";
import Contact from "./components/contact/Contact";
import AttentionOne from "./components/attention/AttentionOne/AttentionOne";
import AttentionTwo from "./components/attention/AttentionTwo/AttentionTwo";

import Connections from "./components/map/Connections";
import ContCred from "./components/credentials/ContCred";
import WorkHeader from "./components/work/WorkHeader";

function App() {
  return (
    <Router>
      <div className="App">
        <ProgressBar
          color1="#212529"
          color2="#3e42c1"
          height="4.5rem"
          position="fixed"
        />
        <StateProvider>
          <Routes>
            <Route
              exact
              path="/"
              style={{ height: "200vh" }}
              element={
                <>
                  <Header />
                  <Navbar />
                  <AttentionOne />
                  <AttentionTwo />
                  <WorkHeader />
                  <ProjectList />
                  <Connections />
                  <ContCred />
                  <FixedFooter />
                </>
              }
            />
          </Routes>

          {/*Product */}
          <Routes>
            <Route
              exact
              path="/product"
              element={
                <>
                  <Navbar />
                  <ProductList />
                  <FixedFooter />
                </>
              }
            />
          </Routes>

          <Routes>
            <Route
              exact
              path="/product/:slug"
              element={
                <>
                  <Navbar />
                  <ProductDetails />
                  <FixedFooter />
                </>
              }
            />
          </Routes>
          {/*Product */}

          {/*Contact */}
          <Routes>
            <Route
              exact
              path="/contact"
              element={
                <>
                  <Navbar />
                  <Contact />

                  <FixedFooter />
                </>
              }
            />
          </Routes>
          {/*Contact */}

          {/*Project */}
          <Routes>
            <Route
              exact
              path="/project/:slug"
              element={
                <>
                  <Navbar />
                  <ProjectDetails />
                  <FixedFooter />
                </>
              }
            />
          </Routes>
          {/*Project */}

          {/*blog route */}
          <Routes>
            <Route
              exact
              path="/blogs"
              element={
                <>
                  <BlogList />
                  <FixedFooter />
                </>
              }
            />
          </Routes>

          <Routes>
            <Route
              exact
              path="/blogs/:slug"
              element={
                <>
                  <Blog />
                  <FixedFooter />
                </>
              }
            />
          </Routes>
        </StateProvider>
      </div>
    </Router>
  );
}

export default App;
