import React, { Component, createContext } from "react";

import { getBlogs } from "../data/AllBlogs";

//products
import { getProductFirstData } from "../data/ProductData/ProductFirstData";
import { getProductSecondData } from "../data/ProductData/ProductSecondData";

//projects
import { getProjectFirstData } from "../data/ProjectData/ProjectFirstData";
import { getProjectSecondData } from "../data/ProjectData/ProjectSecondData";
import { getProjectThirdData } from "../data/ProjectData/ProjectThirdData";
import { getProjectFourthData } from "../data/ProjectData/ProjectFourthData";
import { getProjectFifthData } from "../data/ProjectData/ProjectFifthData";
import { getProjectSixthData } from "../data/ProjectData/ProjectSixthData";
import { getProjectSeventhData } from "../data/ProjectData/ProjectSeventhData";

//Prepare the data layer
export const StateContext = createContext();

class StateProvider extends Component {
  state = {
    //products
    firstProduct: getProductFirstData(),
    secondProduct: getProductSecondData(),

    //projects
    firstProject: getProjectFirstData(),
    secondProject: getProjectSecondData(),
    thirdProject: getProjectThirdData(),
    fourthProject: getProjectFourthData(),
    fifthProject: getProjectFifthData(),
    sixthProject: getProjectSixthData(),
    seventhProject: getProjectSeventhData(),

    blogs: getBlogs(),
  };

  // add to pending func - toggle checkbox
  //  handleSave = (todo) => {
  //    const liveProjects = [...this.state.projects];
  //    const index = liveProjects.indexOf(todo);
  //    liveProjects[index] = { ...liveProjects[index] };
  //    liveProjects[index].save = !liveProjects[index].save;
  //    this.setState({ liveProjects: [...liveProjects] });
  //  };

  render() {
    return (
      <StateContext.Provider
        value={{
          ...this.state,
        }}
      >
        {this.props.children}
      </StateContext.Provider>
    );
  }
}

export default StateProvider;
