import uniqueformrelationship from "../../../img/anlytic/uniqueformrelationship.png";
function Middle() {
  return (
    <div className="attention-two-middle">
      <a
        href="https://drive.google.com/file/d/1xxTZ3LtsInonUC-BhQSYfyx42auXMODw/view?usp=sharing"
        target="__blank"
      >
        <div className="card text-center mt-5 p-0">
          <div className="card-body">
            <h4 className="card-text fw-light text-center">
              A Feature That No One Has Ever Designed
            </h4>

            <div className="card-bottom-img">
              <img
                src={uniqueformrelationship}
                alt="unique-form-relationship"
              />
            </div>
            <a
              className="btn btn-sm btnCTA ramadan__project-btn"
              href="https://anlytic.com/pricing"
              target="__blank"
            >
              Use the app!
            </a>
          </div>
        </div>
      </a>
    </div>
  );
}
export default Middle;
