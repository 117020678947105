import thesoftux from "../../img/works/thesoftux.png";

function WorkHeader() {
  return (
    <div className="work-header">
      <div className="header-left">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="work-header-title">Works</h1>
              <p className="work-header-subtext">
                Dive into my comprehensive case studies that showcase the depth
                and precision of my thought process. Each case study is
                meticulously crafted to highlight the rigorous accuracy and
                innovative solutions. Explore and see how I've transformed
                challenges into exceptional results.
              </p>

              <a href="#works" className="btn btnCTA ramadan__project-btn">
                Check works
              </a>
              <a
                href="https://www.youtube.com/@ProductiveAdda/community"
                target="__blank"
                className="btn btnCTAOutline ramadan__project-btn-outline"
              >
                Understand 'The Soft UX'
              </a>
            </div>
            <div className="col-12 col-md-6 work-header-img">
              <img src={thesoftux} alt="soft workflow" />
              <h5 className="mt-3 text-center fw-light">
                A less famous UX framework I created
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WorkHeader;
