/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import FancyText from "@carefully-coded/react-text-gradient";
import React from "react";
import funtext from "../vdo/funtext.gif";
import ixdfbadge from "../img/badge/ixdfbadge.svg";
import playstorebadge from "../img/badge/playstorebadge.svg";
import courserabadge from "../img/badge/courserabadge.svg";
import stackexchange from "../img/badge/stackexchange.svg";
import adplist from "../img/badge/adplist.svg";
//import talkutube from "../img/badge/talkutube.svg";

import { useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();

  //  const { searchProjects, handleSearch } = this.context;

  return (
    <div className="headerBg">
      <div className="header container-fluid">
        <div className="row ramadan__project-row align-items-center">
          <div className="col-md-8 col-12 mb-5">
            <div className="header__flexOne">
              <div className="header__content">
                <FancyText
                  gradient={{
                    from: "#b8c1cc",
                    to: "#ffffff",
                    type: "linear",
                  }}
                  animate
                  animateDuration={1500}
                >
                  <h1>
                    I UNDERSTAND THE PROBLEM FIRST <br />
                    THEN DESIGN THE SOLUTION
                  </h1>
                </FancyText>
                <p className="header__content-links text-light fw-light">
                  UX / Digital Product Designer with 5½ years of experience
                </p>
              </div>
              <div className="header__content-badges">
                <a
                  href="https://www.interaction-design.org/members/hasan-sharif/certificate/course/f7b88d6e-ed1e-40b3-9090-594c619dfef2"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light"
                >
                  {" "}
                  <img
                    src={ixdfbadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={playstorebadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://www.coursera.org/account/accomplishments/professional-cert/PMT5ZYFU3EFX"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={courserabadge}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://adplist.org/mentors/hasan-sharif"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={adplist}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
                <a
                  href="https://ux.stackexchange.com/users/142295/hasan"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={stackexchange}
                    className="rounded header__content-badges__cred-img"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div className="glowable-text">
              <div className="card border-dark mb-3">
                <div
                  className="card-header glowable-text"
                  style={{ color: "#3e42c1" }}
                >
                  Yet Another Card
                </div>
                <div className="card-body text-light">
                  <h5
                    className="card-title glowable-text mb-4"
                    style={{ fontFamily: "Courier Prime" }}
                  >
                    ID: Hasan Sharif
                  </h5>
                  <p className="fw-light glowable-text">
                    ↳ Crafting intuitive experiences as a UX Designer for 4
                    years
                  </p>
                  <p className="fw-light glowable-text">
                    ↳ Took ☕️ while UI Designing for 2 years{" "}
                  </p>
                  <p className="fw-light glowable-text">
                    ↳ Navigating the roadmap as a Product Manager for 2 years{" "}
                  </p>

                  <p className="fw-light glowable-text">
                    ↳ Played for 2 years with{" "}
                    <i className="fa fa-code" aria-hidden="true"></i> Frontend
                  </p>
                  <p className="fw-light glowable-text">
                    ↳ Founded{" "}
                    <a href="https://sadaqahmadeeasy.com/" target="__blank">
                      Sadaqah Made Easy
                    </a>
                  </p>
                  <p className="fw-light glowable-text">
                    ↳ Technical co-founded{" "}
                    <a href="https://www.organingo.com/" target="__blank">
                      Organingo
                    </a>
                  </p>
                </div>
                <div className="bg-glow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center fun-text">
            <h1>
              It wasn't{" "}
              {/*<iframe src="https://lottie.host/embed/91d5ea38-d957-4f60-81d4-85567e2da9af/wa45AOxSaO.json"></iframe>*/}
              <img src={funtext} alt="fun" />
              &nbsp;right?
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
