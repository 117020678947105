import iknowuserculture from "../../../img/works/iknowuserculture.png";
function Left() {
  return (
    <div className="attention-two-left">
      <a href="https://freelancingsecret.com/en/" target="__blank">
        <div className="card text-center mt-5 p-0">
          <div className="card-body">
            <h2 className="card-title" style={{ color: "#3e42c1" }}>
              <i className="fa fa-user" aria-hidden="true"></i> &nbsp;User
              persona vs Culture
            </h2>

            <h4 className="card-text fw-light text-start">
              A Glimpse of How I Understand Users First
            </h4>

            <div className="card-bottom-img">
              <img src={iknowuserculture} alt="facebook-review-for-gtaf.org" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
export default Left;
