import { useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "../../Context/StateProvider";
import Credentials from "../credentials/Credentials";

function ProjectList() {
  const { firstProject } = useContext(StateContext);
  const { secondProject } = useContext(StateContext);
  const { thirdProject } = useContext(StateContext);
  const { fourthProject } = useContext(StateContext);
  const { fifthProject } = useContext(StateContext);
  const { sixthProject } = useContext(StateContext);
  const { seventhProject } = useContext(StateContext);

  return (
    <div>
      <div className="zakat" id="works">
        <hr className="mb-5" />

        {/*fourth project - bikedot*/}
        <div className="container" style={{ marginTop: "1rem" }}>
          {fourthProject.map((projectFourth) => (
            <div
              className="row ramadan__project-row align-items-center"
              key={projectFourth._ID}
            >
              <div className="col-md-7 col-12 ">
                <h1 className="ramadan__title">
                  {projectFourth.projectHeading}
                </h1>
                <h4 className="ramadan__subTitle">
                  {projectFourth.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectFourth.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
              <div className="col-md-5 col-12 mb-5" key={projectFourth._ID}>
                <div className="ramadan__project-card">
                  <video
                    autoPlay
                    loop
                    muted
                    width="500"
                    height="700"
                    className="project_vdo_mid"
                    style={{
                      clipPath: "inset(2px 2px)",
                    }}
                  >
                    <source
                      src={projectFourth.projectVdo}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/*sixth project - nine star*/}
        <div
          className="container"
          style={{ marginTop: "5rem", marginBottom: "10rem" }}
        >
          {sixthProject.map((projectSixth) => (
            <div
              className="row ramadan__project-row align-items-center"
              key={projectSixth._ID}
            >
              <div className="col-md-5 col-12 mb-5" key={projectSixth._ID}>
                <div className="ramadan__project-card">
                  <div className="ramadan__project-div-2">
                    <video
                      autoPlay
                      loop
                      muted
                      width="444"
                      height="662"
                      className="project_vdo_mid"
                    >
                      <source
                        src={projectSixth.projectVdo}
                        type="video/mp4"
                      ></source>
                    </video>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-12 ">
                <h1 className="ramadan__title">
                  {projectSixth.projectHeading}
                </h1>
                <h4 className="ramadan__subTitle">
                  {projectSixth.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectSixth.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/*third project - measurer*/}
        <div className="container" style={{ marginTop: "5rem" }}>
          {thirdProject.map((projectThree) => (
            <div
              className="row ramadan__project-row align-items-center"
              key={projectThree._ID}
            >
              <div className="col-md-7 col-12 ">
                <h1 className="ramadan__title">
                  {projectThree.projectHeading}
                </h1>
                <h4 className="ramadan__subTitle">
                  {projectThree.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectThree.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
              <div className="col-md-5 col-12 mb-5" key={projectThree._ID}>
                <div className="ramadan__project-card">
                  <img
                    src={projectThree.projectImg}
                    alt="measurer-pro-cover"
                    className="measurer_img project_vdo_mid"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        {/*fifth project - machbazaar*/}
        <div className="container" style={{ marginTop: "10rem" }}>
          {fifthProject.map((projectFifth) => (
            <div
              className="row ramadan__project-row align-items-center"
              key={projectFifth._ID}
            >
              <div className="col-md-6 col-12 mb-5" key={projectFifth._ID}>
                <div className="ramadan__project-card">
                  <img
                    src={projectFifth.projectImg}
                    alt="measurer-pro-cover"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 ">
                <h1 className="ramadan__title">
                  {projectFifth.projectHeading}
                </h1>
                <h4 className="ramadan__subTitle">
                  {projectFifth.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectFifth.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/*first project - finshare */}
        {/*<div className="container" style={{ marginTop: "10rem" }}>
          {firstProject.map((projectOne) => (
            <div className="row ramadan__project-row" key={projectOne._ID}>
              <div className="col-md-7 col-12 align-self-center">
                <h1 className="ramadan__title">{projectOne.projectHeading}</h1>
                <h4 className="ramadan__subTitle">
                  {projectOne.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectOne.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>

              <div className="col-md-5 col-12 mb-5" key={projectOne._ID}>
                <div className="ramadan__project-card">
                  <video
                    autoPlay
                    loop
                    muted
                    width="500"
                    height="700"
                    className="ramadan__project-vdo"
                    style={{
                      clipPath: "inset(2px 2px)",
                    }}
                  >
                    <source
                      src={projectOne.projectVdo}
                      type="video/mp4"
                    ></source>
                  </video>
                </div>
              </div>
            </div>
          ))}
        </div>*/}

        {/*second project - sme*/}
        <div className="container" style={{ marginTop: "10rem" }}>
          {secondProject.map((projectTwo) => (
            <div className="row ramadan__project-row" key={projectTwo._ID}>
              <div className="col-md-6 col-12 align-self-center">
                <h1 className="ramadan__title">{projectTwo.projectHeading}</h1>
                <h4 className="ramadan__subTitle">
                  {projectTwo.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectTwo.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
              <div className="col-md-6 col-12 mb-5">
                <div className="ramadan__project-card ramadan__project-card_2">
                  {" "}
                  <div className="ramadan__project-div-2">
                    <video
                      autoPlay
                      loop
                      muted
                      width="444"
                      height="662"
                      className="ramadan__project-vdo ramadan__project-vdo-2"
                    >
                      <source
                        src={projectTwo.projectVdo}
                        type="video/mp4"
                      ></source>
                    </video>
                    <img
                      src={projectTwo.projectImg}
                      className="ramadan__project-img_2"
                      alt="project-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/*seventh project - gtaf-hadith*/}
        <div
          className="container"
          style={{ marginTop: "5rem", marginBottom: "0" }}
        >
          {seventhProject.map((projectSeventh) => (
            <div
              className="row ramadan__project-row align-items-center"
              key={projectSeventh._ID}
            >
              <div className="col-md-5 col-12 mb-5" key={projectSeventh._ID}>
                <div className="ramadan__project-card">
                  <div className="ramadan__project-div-2">
                    <img
                      src={projectSeventh.projectImg}
                      alt="measurer-pro-cover"
                      className="measurer_img project_vdo_mid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-12 align-self-start mt-5">
                <h1 className="ramadan__title">
                  {projectSeventh.projectHeading}
                </h1>
                <h4 className="ramadan__subTitle">
                  {projectSeventh.projectSubTitle}
                </h4>
                <Link
                  to={`/project/${projectSeventh.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/*credentials */}
      {/*<hr style={{ marginTop: "8rem" }} />*/}

      {/*<div
        className="container more-about"
        id="#credentials"
        style={{ marginTop: "1rem", marginBottom: "3rem" }}
      >
        <h5 style={{ marginTop: "3rem", marginBottom: "2rem" }}>
          Credentials —
        </h5>
        <Credentials />
      </div>

      
      <hr style={{ marginTop: "1rem" }} />
      <div
        className="container more-about"
        style={{ marginTop: "rem", marginBottom: "8rem" }}
      >
        <h5 style={{ marginTop: "3rem", marginBottom: "2rem" }}>
          Contributions —
        </h5>
        <h4 className="mb-4">
          Writings:{" "}
          <span>
            <a
              href="https://hrahatsharif.medium.com/mvp-understand-it-first-7dd6fc82d80c"
              target="__blank"
              className="text-primary"
            >
              MVP - Understand it first
            </a>
            &nbsp; and more...
          </span>
        </h4>
        <h4>
          On forum:{" "}
          <a
            href="https://ux.stackexchange.com/users/142295/hasan-sharif"
            className="text-primary"
            target="__blank"
          >
            Helping UX folks on Stackexchange
          </a>
        </h4>
      </div>*/}
    </div>
  );
}

export default ProjectList;
