/* eslint-disable jsx-a11y/anchor-is-valid */

// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Contact() {
  const [value, setValue] = React.useState("hrahatsharif@gmail.com");
  const [copied, setCopied] = React.useState(false);
  const onChange = React.useCallback(({ target: { value } }) => {
    setValue(value);
    setCopied(true);
  }, []);
  const onClick = React.useCallback(({ target: { innerText } }) => {
    console.log(`Clicked on "${innerText}"!`);
  }, []);
  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);
  return (
    <div className="contact bg-dark">
      <div
        className="container"
        style={{ marginTop: "4rem", marginBottom: "0rem" }}
      >
        <div className="row ramadan__project-row align-items-start">
          <div className="col-md-9 col-12 mb-5">
            <h1
              className="text-light fw-bold pt-5"
              style={{ fontFamily: "Yeseva One", letterSpacing: "0px" }}
            >
              Hey There! Did Someone <br /> Say🍦Ice-Cream? Let's Meet!
            </h1>
            <p className="text-light" style={{ fontSize: "18px" }}>
              I always enjoy connecting with new people for a purpose, <br />{" "}
              enjoy learning things from every events.
              <br />
              <br />
              Don't waste your time finding a way to ping me! <br />
              Just Linkedin me.
            </p>

            <div className="d-flex flex-row">
              <a
                type="button"
                className="btn btnCTA ramadan__project-btn me-2"
                target="__blank"
                href="https://www.linkedin.com/in/hasansharifrht/"
              >
                Send a Linkedin DM
              </a>
              <CopyToClipboard onCopy={onCopy} text={value}>
                <a
                  type="button"
                  className="btn btnCTA_outline ramadan__project-btn"
                  target="__blank"
                  onChange={onChange}
                >
                  hrahatsharif@gmail.com{" "}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="copy"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 512"
                    width="20px"
                    height="20px"
                  >
                    <path
                      fill="#EEEEEE"
                      d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
                    ></path>
                  </svg>
                </a>
              </CopyToClipboard>
            </div>
          </div>
          <div className="col-md-3 col-12 mb-5">
            {" "}
            <div className="d-flex flex-column">
              <section className="adplist-section mb-4">
                <iframe
                  src="https://adplist.org/widgets/impact?src=hasan-sharif"
                  title="Impact"
                  width="100%"
                  height="100%"
                  loading="lazy"
                  style={{
                    border: "1px",
                    borderRadius: "10px",
                    background: "#000000",
                  }}
                ></iframe>
              </section>
              <div className="card">
                <div className="table table-danger">
                  <h5 className="card-caption text-light">Available for</h5>
                  <div className="card-description">
                    <p> ✔ Ice cream</p>
                    <p> ✔ Lead/Senior UX Role</p>
                    <p> ✔ Mentoring</p>
                    <p> ✔ Events</p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="table table-danger">
                  <h5 className="card-caption text-danger">
                    Not interested in
                  </h5>
                  <div className="card-description">
                    <p> ✗ Biriyani</p>
                    <p> ✗ Clickbait events</p>
                    <p> ✗ Speculation</p>
                    <p> ✗ Banking/Fashion/Sports/Alcohol</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<iframe
        src="https://gifer.com/embed/DO5"
        width="480"
        height="480.000"
        allowFullScreen
      ></iframe>*/}
    </div>
  );
}

export default Contact;
