import measurerflowvdo from "../../vdo/measurerflowvdo.mp4";
import measurerproto1 from "../../vdo/measurerproto1.mp4";
import measurerproto2 from "../../vdo/measurerproto2.mp4";
import measurerproto3 from "../../vdo/measurerproto3.mp4";
import measurerprocover from "../../img/measurer/measurerprocover.jpg";
import measurertoolflow from "../../img/measurer/measurertoolflow.jpg";
import measurergridlayout1 from "../../img/measurer/measurergridlayout1.jpg";
import measurergridlayout2 from "../../img/measurer/measurergridlayout2.jpeg";
import measurerpersonas from "../../img/measurer/measurerpersonas.jpg";
import measurerempathymap from "../../img/measurer/measurerempathymap.jpg";
import measurersketchone from "../../img/measurer/measurersketchone.jpg";
import measurersketchtwo from "../../img/measurer/measurersketchtwo.jpg";
import measurersketchthree from "../../img/measurer/measurersketchthree.jpg";
import measurercompone from "../../img/measurer/measurercompone.png";
import measurercomptwo from "../../img/measurer/measurercomptwo.png";
import measurercompthree from "../../img/measurer/measurercompthree.png";
import measurercompfour from "../../img/measurer/measurercompfour.png";
//import measurercompfive from "../../img/measurer/measurercompfive.jpg";
import measurercompfive from "../../img/measurer/measurercompfive.webp";
import measurercompsix from "../../img/measurer/measurercompsix.png";
import measurerhome from "../../img/measurer/measurerhome.jpg";
import measurerforms from "../../img/measurer/measurerforms.jpg";
import measurerusertestingreport from "../../img/measurer/measurerusertestingreport.jpg";

import measurersilde1 from "../../img/measurer/measurersilde1.jpg";
import measurersilde2 from "../../img/measurer/measurersilde2.jpg";
import measurersilde3 from "../../img/measurer/measurersilde3.jpg";
import measurersilde4 from "../../img/measurer/measurersilde4.jpg";
import measurersilde5 from "../../img/measurer/measurersilde5.jpg";
import measurersilde6 from "../../img/measurer/measurersilde6.jpg";
import measurersilde7 from "../../img/measurer/measurersilde7.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

//last serial: 1.5

const ProjectThirdData = [
  {
    _ID: "sdqme01.3",
    projectImg: measurerprocover,
    //projectVdo: measurerprointeraction,
    projectHeading: "Measurer Pro - Towards decarbonisation",
    projectSubTitle:
      "Measurer Pro - A design tool to design ship engine by getting required power, volumn and total cost of ownership measuring hydrogen propulsion and others of H2 engine ships as result.",
    slug: "measurerpro",
    based: "Europe",
    tag: "Green tech, Marine",
    timeline: <span> 03/23/2022 - 06/01/2022</span>,
    projectDetails: (
      <div>
        <div className="row">
          {/*intro */}
          <div className="col-md-12 mb-5">
            <h3 style={{ lineHeight: "42px" }}>
              Measurer pro is a maritime transport sector based project promotes
              total decarbonisation of waterborne transport providing high
              efficiency and energy density with no local greenhouse gas
              emissions. It demonstrates the importance of reducing pollution
              and improve our air and water quality. Measurer pro will launch a
              public design tool which can be used by respective researcher and
              stakeholders to design ship enginer.
              <br />
              <br />
              Measurer pro is a design tool to design ship engine that takes
              varity of inputs based on user type. Standard users need to give
              propulsion power, operational profile data and capacity
              dimensioning to get expected result. Results has two types of
              view, that is, table and histogram. Advanced users need to play
              with more parameters of feasibility studies and analysis.
            </h3>

            <p
              className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
              style={{ fontSize: "22px" }}
            >
              <i>
                To comply with my confidentiality agreement I have omitted and
                appropriated confidential information. These documentation and
                designs are the reinterpretation of the original.
              </i>
            </p>
          </div>

          <div className="col-md-6 col-12 mt-4">
            <h4>
              <u>Problem statement</u>
            </h4>
            <p>
              R&D team of Measurer ltd consists of researchers and professors
              whose regular job is designing marine machines etc based on
              complex equations. They use MS Excel to make equations and get
              results, increasing their frustrations day-by-day.{" "}
              <b className="fw-bold">
                In order to make the process seamless and easier,
              </b>{" "}
              Measurer R&D team badly needs a design tool where they can
              calculate data and make designs of it.
            </p>
          </div>

          <div className="col-md-6 col-12 mt-4">
            <h4>
              <u>My role</u>
            </h4>
            <ul>
              <li>User research</li>
              <li>Layout sketch</li>
              <li>UI design - company website</li>
              <li>UI design - calculator tool</li>
              <li>Build component library</li>
              <li>Prototyping</li>
            </ul>
          </div>

          {/*design tool flow to main screen */}

          <div className="col-md-12 border rounded shadow-sm mt-5">
            <p className="text-center mt-3 mb-5" style={{ fontSize: "24px" }}>
              Let's first view the scaled-down prototype of the design tool for
              standard user -{" "}
              <span className="text-dark">
                <u>what is it and how it works?</u>
              </span>
            </p>
            <video
              autoPlay
              loop
              muted
              width="1000"
              style={{
                clipPath: "inset(2px 2px)",
                alignSelf: "center",
              }}
            >
              <source src={measurerflowvdo} type="video/mp4"></source>
            </video>
          </div>

          {/*Design challenges */}
          <div className="row" style={{ marginTop: "5rem" }}>
            <div className="col-md-6">
              <p className="text-left text-dark" style={{ fontSize: "24px" }}>
                Instead of demonstrating company website design process, I'd
                like to start with the main part of this project that is the
                design tool. It's a calculator like tool where users will give
                input and anticipates result by not closing the input forms so
                that results can be manually validated if the context demands.
              </p>
            </div>
            <div className="col-md-6">
              {" "}
              <p className="text-left text-dark" style={{ fontSize: "24px" }}>
                There are two user type in this tool, Standard & Advanced. In
                this demonstration, use case of Standard users will be focused
                for better understanding. Advanced parameters flow will remain
                untouched.
              </p>
            </div>

            <div className="col-md-6" style={{ marginTop: "4rem" }}>
              <p
                className="text-left text-dark mt-3"
                style={{ fontSize: "24px" }}
              >
                <p>Main design challenges were:</p>
                <ul>
                  <li className="mb-3">
                    Creating an unique layout where navigations, inputs elements
                    and results need to be displayed within single screen
                  </li>
                  <li className="mb-3">
                    Determining mental model of a unique group of target
                    audience - researchers and mariners
                  </li>
                  <li className="mb-3">
                    {" "}
                    Avoiding unnecessary elements that don’t communicate with
                    data
                  </li>
                </ul>
              </p>
            </div>

            <div className="col-md-6" style={{ marginTop: "4rem" }}>
              <p
                className="text-left text-dark mt-3"
                style={{ fontSize: "24px" }}
              >
                <p className="text-success">How do I do this?</p>
                <ul className="text-success">
                  <li className="mb-3">
                    {" "}
                    For better organization, I used a 12-column grid layout with
                    16px of gutters and 105 of width
                  </li>
                  <li className="mb-3">
                    {" "}
                    Conducting user research with user persona and user journey
                    map for the best possible ideation
                  </li>
                  <li className="mb-3">
                    {" "}
                    Optimizing ink-ratio - dividing data-ink by the total ink
                    required to visual layout
                  </li>
                </ul>
              </p>
            </div>
          </div>

          {/*user persona */}
          <div className="row" style={{ marginTop: "5rem" }}>
            <div className="col-md-12">
              <h3 style={{ lineHeight: "42px" }}>
                Putting user front and center, I tried to follow a flexible
                non-linear way of reaching into a better primary understanding
                of user's perception and expectation -
                <b className="fw-bold">
                  {" "}
                  How target users would perceive a Design tool on their
                  display!{" "}
                </b>
                <br />
                <br />
                Before using this design tool, they were using MS Excel about
                which they have complained a lot.
              </h3>

              <div className="d-flex justify-content-around align-items-center mt-5">
                <div className="flex-pill p-2 shadow rounded">
                  Evaluate end users tech skill
                </div>
                <div className="flex-pill p-2 shadow rounded">
                  Assess users perception & navigation process
                </div>
                <div className="flex-pill p-2 shadow rounded">
                  Understand users common behavior
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <img
                src={measurerpersonas}
                alt="measurer-personas"
                className="w-100 mt-4"
              />
              <p
                className="text-center text-secondary"
                style={{ fontSize: "14px", marginTop: "-5rem" }}
              >
                User persona of professor & researcher
              </p>
            </div>
          </div>

          {/*empathy map*/}
          <div className="row" style={{ marginTop: "5rem" }}>
            <div className="col-md-4 col-12 align-self-center">
              <h4 className="mb-3">
                Reconcilation of pain points of using MS Excel:
              </h4>
              <ul style={{ fontSize: "16px" }}>
                <li>
                  The more spreadsheets, the harder to assemble information
                  which creates risk of data manipulation{" "}
                </li>
                <li>
                  Inaccurate reporting is caused by many Excel spreadsheets
                </li>
                <li>
                  The more data in the spreadsheet, the slower Excel is to
                  respond
                </li>
                <li>
                  {" "}
                  Manual effort to add/change formula is prone to human error
                </li>
              </ul>
              <span style={{ fontSize: "16px" }}>
                User research with empathy helped me to get these pain points to
                make good UX.
              </span>
            </div>
            <div className="col-md-8 col-12">
              <img
                src={measurerempathymap}
                alt="measurer-empathy-map"
                className="shadow-sm"
                style={{ width: "900px" }}
              />
              <p
                className="text-center text-secondary"
                style={{ fontSize: "14px", marginTop: "3rem" }}
              >
                Empathy map
              </p>
            </div>
          </div>

          {/*user flow explain */}
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <h3 className="mt-5" style={{ lineHeight: "42px" }}>
              To use the most efficient visualization in the tool's layout, an
              elevated user task flow was needed for the end users to give best
              possible UX. Instead of letting users straight to the part where
              they will give input, this flow makes thing{" "}
              <b className="fw-bold"> discoverable first.</b> To reach that aha
              moment (showing result) quickly, the flow is concerned of reducing
              time to value (TTV).
            </h3>
            <img
              src={measurertoolflow}
              alt="measruer-user-flow"
              className="w-100 shadow-sm rounded border mt-2"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              User flow of the design tool
            </p>
          </div>

          {/*sketching layout - ideation - 1  */}
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <h3 className="mt-5" style={{ lineHeight: "42px" }}>
              Sketching is a powerful tool to solve problems in hand.
              Encompassing a regular calculator layout, a common dashboard
              layout and the MS excel spreadsheet, with several times of
              iteration and refining,
              <b className="fw-bold">
                {" "}
                a blueprint of functionality has been outlined.
              </b>{" "}
            </h3>
            <img
              src={measurersketchone}
              alt="measrurer-sketch-one"
              className="w-100 shadow-sm rounded border mt-4"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Ideation - Sketch - 2
            </p>

            <img
              src={measurersketchtwo}
              alt="measrurer-sketch-two"
              className="w-100 shadow-sm rounded border mt-2"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Primary blueprint - Sketch
            </p>
          </div>

          {/*grid layout making - 1 */}
          <div className="col-md-12" style={{ marginTop: "4rem" }}>
            <h4 className="mt-5 text-center" style={{ lineHeight: "42px" }}>
              For better organization, I used a 12-column grid layout with 16px
              of gutters and 105 of width
            </h4>
            <img
              src={measurergridlayout1}
              alt="measurer-grid-layout"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Uses of grid layout
            </p>
          </div>

          {/*sketching layout - ideation - 1  */}
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <h3 className="mt-5" style={{ lineHeight: "42px" }}>
              Another idea was generated with another type of orientation of
              layout that is common{" "}
              <b className="fw-bold"> vertical orientation</b>. Both of the
              orientations (Sketched version) were validated by user testing.
            </h3>
            <img
              src={measurersketchthree}
              alt="measrurer-sketch-one"
              className="w-100 shadow-sm rounded border mt-4"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Ideation - Sketch - 2
            </p>
            <img
              src={measurergridlayout2}
              alt="measurer-grid-layout"
              className="w-100 shadow-sm rounded border mt-5"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Vertical orientation - High fidelity
            </p>
          </div>

          {/*user testing report*/}
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <h3 className="mt-5" style={{ lineHeight: "42px" }}>
              To gain a deeper understanding of target audience's needs and
              frustrations, I did conduct a minimal remote usability test. It
              helped me
              <b className="fw-bold">
                {" "}
                to discover design flaws as well as areas of confusion.
              </b>
              . One of the goals of this test session was to discover users'
              preferred way of navigating menu to the last step of getting
              result. In the below image, some significant pain points and
              opportunities, emotions behind interactions are pointed out for
              better clarification.
            </h3>
            <img
              src={measurerusertestingreport}
              alt="measrurer-user-testing-report"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "-10rem" }}
            >
              User testing report observing user journey
            </p>
          </div>

          {/*components - 1,2,3*/}
          <div className="col-md-12" style={{ marginTop: "4rem" }}>
            <h4 className="mt-5" style={{ lineHeight: "42px" }}>
              Building component library was my another task assigned by. Each
              and every micro-interactions were created to smooth the process of
              user testing and to observe how users react with those
              interactions
            </h4>
            <img
              src={measurercompone}
              alt="measurer-comp-1"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Component library with micro-interaction connected - 1
            </p>
          </div>

          <div
            className="row justify-content-around"
            style={{ marginTop: "12rem", marginBottom: "7rem" }}
          >
            <div className="col-md-4">
              <video
                autoPlay
                loop
                muted
                width="200"
                //height="700"
                //className="ramadan__project-vdo"
                style={{
                  clipPath: "inset(2px 2px)",
                }}
              >
                <source src={measurerproto1} type="video/mp4"></source>
              </video>
            </div>

            <div className="col-md-4">
              <video
                autoPlay
                loop
                muted
                width="280"
                height="300"
                //className="border"
                style={{
                  clipPath: "inset(2px 2px)",
                }}
              >
                <source src={measurerproto2} type="video/mp4"></source>
              </video>
            </div>
            <div className="col-md-4">
              <video
                autoPlay
                loop
                muted
                width="400"
                //height="700"
                //className="ramadan__project-vdo"
                style={{
                  clipPath: "inset(2px 2px)",
                }}
              >
                <source src={measurerproto3} type="video/mp4"></source>
              </video>
              {/*
              <iframe
                src="https://drive.google.com/file/d/1JOZBkMwOllSNg7ameXnAj1dlagusMeux/preview"
                width="640"
                height="480"
                allow="autoplay"
                title="vdo"
              ></iframe>*/}
            </div>
          </div>

          <div className="col-md-7" style={{ marginTop: "4rem" }}>
            <img
              src={measurercomptwo}
              alt="measurer-comp-2"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Dropdown interaction connected- 2
            </p>
          </div>

          <div className="col-md-5" style={{ marginTop: "4rem" }}>
            <img
              src={measurercompthree}
              alt="measurer-comp-3"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Interaction connected in input field - 3
            </p>
          </div>

          <div className="col-md-12" style={{ marginTop: "4rem" }}>
            <img
              src={measurercompfour}
              alt="measurer-comp-4"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Interaction connected in result tab - 4
            </p>
          </div>

          <div className="col-md-6" style={{ marginTop: "4rem" }}>
            <img
              src={measurercompfive}
              alt="measurer-comp-5"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Building core components - 5
            </p>
          </div>

          <div className="col-md-6" style={{ marginTop: "4rem" }}>
            <img
              src={measurercompsix}
              alt="measurer-comp-6"
              className="w-100 shadow-sm rounded border mt-3"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Building core components with connections - 6
            </p>
          </div>

          {/*Screens slider*/}
          <div className="row justify-content-center">
            <div className="col-md-8 shadow-sm" style={{ marginTop: "4rem" }}>
              <h4
                className="mt-3 mb-4 text-center"
                style={{ lineHeight: "42px" }}
              >
                Final screens (Slider view)
              </h4>
              <Carousel autoPlay infiniteLoop stopOnHover>
                <div>
                  <img
                    src={measurersilde1}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
                <div>
                  <img
                    src={measurersilde2}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
                <div>
                  <img
                    src={measurersilde3}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
                <div>
                  <img
                    src={measurersilde4}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
                <div>
                  <img
                    src={measurersilde5}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
                <div>
                  <img
                    src={measurersilde6}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
                <div>
                  <img
                    src={measurersilde7}
                    alt="measurer-screens"
                    className="border"
                  />
                </div>
              </Carousel>
            </div>
          </div>

          {/*website screens*/}
          <div className="row">
            <div className="col-md-12" style={{ marginTop: "8rem" }}>
              <h4
                className="mt-3 mb-4 text-center"
                style={{ lineHeight: "42px" }}
              >
                Company website - UI
              </h4>
              <h3 className="text-center mb-5" style={{ lineHeight: "40px" }}>
                Client sent me the requirements of how should the company
                website look like including hand sketch (prepared by the client
                himself) along with detailed sample design. Timeline of
                designing company's website was short and requirements were
                strict that's why the process was simplified.
              </h3>
              <img
                src={measurerhome}
                alt="measurer-website"
                className="w-100 mt-3"
              />
              <p
                className="text-center text-secondary mt-3"
                style={{ fontSize: "14px" }}
              >
                Home page - Cluster view
              </p>
            </div>

            <div className="col-md-12" style={{ marginTop: "5rem" }}>
              <img
                src={measurerforms}
                alt="measurer-website"
                className="w-100 mt-3"
              />
              <p
                className="text-center text-secondary mt-3"
                style={{ fontSize: "14px" }}
              >
                Company's data table and contact form UI
              </p>
            </div>
          </div>
        </div>

        {/*The impact and lesson learnt */}
        <div
          className="d-flex justify-content-center p-3"
          style={{ marginTop: "5rem" }}
        >
          <div className="flex-div border rounded bg-light shadow-sm p-3 me-3">
            <h2 className="text-center mb-4">
              <u>The impact</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Measurer R&D team celebrated the moment of getting accurate
                result within expected time
              </li>

              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Eliminated the risk of depending on gut feelings of end users
              </li>
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>{" "}
                Developers built, deployed and linked the design tool with
                company website following the prototype
              </li>
            </ul>
          </div>

          <div className="flex-div border rounded shadow-sm p-3">
            <h2 className="text-center mb-4">
              <u>Lesson learnt</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Simplicity is top priority to researchers and professors
              </li>

              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Gaining good ink-ratio is the key working with data
                visualization
              </li>
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Not every 'Aha-moment' is really a 'Aha-moment'
              </li>
            </ul>
          </div>
        </div>
        <p className="text-center" style={{ marginTop: "5rem" }}>
          - A big thank you for the time you spent reading this case study -
        </p>
      </div>
    ),
  },
];

export function getProjectThirdData() {
  return ProjectThirdData;
}
