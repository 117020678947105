import ninecovervdo from "../../vdo/ninecovervdo.mp4";
import ninecheckoutvdo from "../../vdo/ninecheckoutvdo.mp4";
import ninelofiproto from "../../vdo/ninelofiproto.mp4";
import ninehomenav from "../../vdo/ninehomenav.mp4";

import nineintro from "../../img/nine/nineintro.webp";
import nineucd from "../../img/nine/nineucd.webp";
import ninecostanalysis from "../../img/nine/ninecostanalysis.webp";
import ninewireframe from "../../img/nine/ninewireframe.webp";
import ninesurvey from "../../img/nine/ninesurvey.webp";
import ninepersona from "../../img/nine/ninepersona.webp";
import nineswot from "../../img/nine/nineswot.webp";
import nineusertest from "../../img/nine/nineusertest.webp";

import ninehomecat from "../../img/nine/ninehomecat.webp";
import ninepdpcart from "../../img/nine/ninepdpcart.webp";
import ninefiltercatlist from "../../img/nine/ninefiltercatlist.webp";
import ninecheckout1 from "../../img/nine/ninecheckout1.webp";
import ninecheckout2 from "../../img/nine/ninecheckout2.webp";
import nineprofilewish from "../../img/nine/nineprofilewish.webp";
import ninetrackonboard from "../../img/nine/ninetrackonboard.webp";
import nineanimation from "../../img/nine/nineanimation.webp";

import nineqr from "../../img/nine/nineqr.png";

//last serial: 1.6

const ProjectSixthData = [
  {
    _ID: "sdqme01.6",
    //projectImg: ninecover,
    projectVdo: ninecovervdo,
    projectHeading: "Nine Star - A Sweden based online food store",
    projectSubTitle: (
      <>
        An online food ordering and delivery app owned by Nine Star Food Store
        located in Sweden, provides one-stop food delivery features with a
        convenient way to deliver food from the store. I was engaged as UX
        Designer particularly focused on proper research on fixed customers.
      </>
    ),
    slug: "nine-star",
    based: "Europe",
    tag: "Online food order",
    timeline: <span> 09/22/2020 - 10/25/2020</span>,
    projectDetails: (
      <div className="row">
        {/*intro */}
        <div className="col-md-12 mb-5">
          <h3>
            An online food ordering and delivery app owned by Nine Star Food
            Store located in Sweden, provides one-stop food delivery features
            with a convenient way to deliver food from the store. I was engaged
            as UX Designer particularly focused on proper research on fixed
            customers.
          </h3>
          <p
            className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
            style={{ fontSize: "22px" }}
          >
            <i>
              To comply with my confidentiality agreement I have omitted and
              appropriated confidential information. These documentation and
              designs are the reinterpretation of the original.
            </i>
          </p>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <h4>
            <u>Problem statement</u>
          </h4>
          <p>
            Nine star food store is one the oldest food stores in Sweden serving
            for 15 years throughout the country. The usually sell Fast food and
            Cakes in their offline store. In last 5 years business report, 9
            Star observed a serious change in their customer feeback and order
            placing process. 75% of their fixed customers placed food orders
            through social networking channels and customers submitted reviews
            of demanding an app of 9 Star. Now, 9 Star wants to launch their app
            quickly where target customers can use the app effortlessly. They
            also want the app's experience to be well compiled with human's
            psychology.
          </p>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <h4>
            <u>My role</u>
          </h4>
          <ul>
            <li>User research</li>
            <li>Wireframe</li>
            <li>UI design</li>
            <li>Prototype</li>
          </ul>
        </div>

        {/*intro screens */}
        <div className="col-md-12">
          <img src={nineintro} alt="nine-star-intro" className="w-100" />
          <p
            className="text-center text-secondary mt-5"
            style={{ fontSize: "14px" }}
          >
            9 Star app screens
          </p>
        </div>

        {/*last insight */}
        <div className="row" style={{ marginTop: "2rem" }}>
          {/*The challenge */}
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">The challenge</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              The client came to me with two main objectives: (i) evaluating
              users current frustrations with ordering food online and present
              research data, (ii) make the experience of the app compatible with
              children
            </p>
          </div>
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">Timeline</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              Nine star wanted to launch the app at the very beginning of 2021
              and the duration I got was 1 month to deliver the design. To
              collect user's data keeping short period of time in mind, I
              conducted couple of remote user interview and SWOT analysis on
              local competitors. To identify gaps local competitors aren’t
              filling, I spend significant time on reading competitors’ reviews
              from social media and play store.
            </p>
          </div>
          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
            <h3 className="mb-4 fw-bold">With stakeholders</h3>
            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
              Managers of Nine Star gave me opportunities to talk with their
              lead chef, technical operator and current offline delivery person.
              These people were necessary for the project to conduct SWOT
              analysis effectively. As developer team had 2 months after my
              delivery, I had to maintain frequent communication with them to
              discuss on feasibility and to avoid roadblocks.
            </p>
          </div>
        </div>

        {/*design framework */}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-7">
            <p>
              {" "}
              <b>Method used</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              Designing an app for an established business is crucial due to
              importance of balancing cost-benefit. Instead of directly
              implementing any specific framework within my process, I followed
              UCMD (User-centered Mobile Design) process which is based on UCD
              (User-centered Design), was very congenial for the current context
              of this project where proper prioritization and implementation
              were in major concern to make a balance of increasing the sale and
              lowering the costs after launching the app.
            </h3>
          </div>
          <div className="col-md-5 align-self-center">
            <img src={nineucd} alt="nine-star-ucd" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              User-centered mobile design life cylcle (Author: Paul Veugen)
            </p>
          </div>
        </div>

        {/*challenge on mobile ux */}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-7 col-12">
            <p>
              {" "}
              <b>Matching mobile environments first</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              Another challenge was meeting mobile-first user's needs in terms
              of accessibility, discoverability and optimized experience so that
              mobile users can reach to successful path of the task flow,
              on-the-go. Mobile is now ubiquitous so as the use cases of it. By
              taking this into accout, I pointed out three goals to be achieved
              under this circumstances mentionad on right beside this.
            </h3>
          </div>
          <div className="col-md-3 col-12 ms-5 align-self-center">
            <h4 className="mb-4">Three goals:</h4>
            <p className="fw-bold">1. Simplify navigation</p>
            <p className="fw-bold">
              2. Offer maximum effect for minimum interaction
            </p>
            <p className="fw-bold">3. Maintain brand</p>
          </div>
        </div>

        {/*user research - survey > pesona*/}
        <div className="row" style={{ marginTop: "10rem" }}>
          {/*survey */}
          <div className="col-md-12">
            <p>
              {" "}
              <b>Analysis - Exploratory User Research </b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Without further ado, I conducted an online survey on Exploratory
              research phase focusing on gathering a thorough understanding of
              the end user’s ( Nine Star's fixed customers) needs and goals.
              Survey form was sent to the fixed customers via mail.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5 text-center">
            <img
              src={ninesurvey}
              alt="nine-star-persona "
              className="w-75 mb-2"
            />
            <p
              className="text-center text-secondary mb-5"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Survey outcomes
            </p>
          </div>
          {/*persona */}
          <div className="col-md-12" style={{ marginTop: "5rem" }}>
            <p>
              {" "}
              <b>Analysis - Secondary User Research </b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              Next, on secondary research phase, I collected fixed customers
              data from their hard copy data sheet / diary where they documented
              fixed customer's weekly order, debt, contact information, short
              feedback etc from which I formed up the user persona
              representation of the particular group of our users. To understand
              the context of use, I also abstracted "Scenarios of interaction"
              of the target users so that It can help make an efficient design
              for the mobile users.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5 border">
            <img src={ninepersona} alt="nine-star-persona " className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Fixed customer's persona
            </p>
          </div>
          <div className="text-center" style={{ marginTop: "5rem" }}>
            <h3 style={{ lineHeight: "40px" }}>
              ❝By combining data of exploratory and secondary research, it
              helped me thoroughly to make hypotheses-driven design decisions❞
            </h3>
          </div>
        </div>

        {/*competitive analysis - SWOT*/}
        <div className="row" style={{ marginTop: "10rem" }}>
          {/*survey */}
          <div className="col-md-12">
            <p>
              {" "}
              <b>Analysis - Competitive audit by SWOT </b>
            </p>
            <h3 style={{ lineHeight: "40px", marginBottom: "5rem" }}>
              To evaluate a company's (Nine Star) competitive position as well
              as current and future potential, I used SWOT framework. SWOT was
              helpful in this short time project and more compatible in making
              realistic, fact-based decision. By evaluating internal factors,
              SWOT framework helped leveraging Strengths and external evaluation
              helped eliminating threats and strengthen areas where it is weak.
              Also, this SWOT analysis phase was performed remotely with the
              presence of client himself.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5 text-center">
            <img src={nineswot} alt="nine-star-swot" className="w-75 mb-2" />
            <p
              className="text-center text-secondary mb-5"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Visual Overview of SWOT analysis of 9Star's competitors
            </p>
          </div>
        </div>

        {/*lo-fi wireframe*/}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Design - Building Skeleton</b>
            </p>
            <h3 style={{ lineHeight: "35px" }}>
              Due to short stretch of time, UCD approach propped me well in
              developing the scaled-down version that is, low-fidelity
              prototype. This quick and cost-effective technique helped
              explicitly to catch major usability problems early. After
              designing the low-fidelity wirefames, I quickly jumped making the
              low-fi prototype based on basic flow of searching food to
              completion of the order process for conducting usability test.
            </h3>
          </div>{" "}
          <div className="col-md-12 mt-5">
            <img
              src={ninewireframe}
              alt="nine-star-wireframe"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "1rem" }}
            >
              Skeleton of the app
            </p>
          </div>
          <div className="text-center" style={{ marginTop: "5rem" }}>
            <h3 style={{ lineHeight: "40px" }}>
              ❝This low-fidelty design was made entirely based on the analysis I
              tooled. One of the core outcomes of that analysis was "Easy
              process in finding items to order confirmation which I outlined in
              this low-fi flow.❞
            </h3>
          </div>
        </div>

        {/*lo-fi proto - vdo*/}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-4 align-self-center">
            <video
              autoPlay
              loop
              muted
              width="300"
              style={{
                clipPath: "inset(2px 2px)",
              }}
            >
              <source src={ninelofiproto} type="video/mp4"></source>
            </video>
          </div>
          <div className="col-md-8">
            <p>
              {" "}
              <b>Evaluation - Test Low-fidelity Prototype</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              After completing the low-fidelity design, I made the prototype and
              send the link to some target end users in order to conduct remote
              usability test. As constrained timeline, this process was chosen
              because it is cheaper to change a product early in the development
              process than to make change after you develop the site.
              <br />
              <br />
              This prototype presentation is recorded with the cursor to easily
              track user's capability and check the flow is intuitive or not.
            </h3>
          </div>{" "}
        </div>

        {/*lo-fi proto - remote Unmoderated User Tests*/}
        <div className="row" style={{ marginTop: "10rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Evaluation - Unmoderated Remote User Test (URUT)</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              Starting with explaining the testing goal to the participant, I
              conducted an unmoderated remote usability test using Maze which is
              much faster in this project and hence, generated results within
              just 5-6 hours. As the main focus of the study was to the flow of{" "}
              <b className="fw-bold">
                finding an item and confirm placing the order
              </b>
              , rather than an overall review, and timeframes were tight, this
              method of user testing was way more compatible.
            </h3>
          </div>

          <div className="col-md-12 mt-5">
            <img
              src={nineusertest}
              alt="nine-star-user-test"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "2rem" }}
            >
              Unmoderated remote user testing session
            </p>
          </div>
          <div className="text-center">
            <h3>URUT Analysis</h3>
            <h4 style={{ marginTop: "5rem" }}>
              Goal: <b className="fw-bold">Find a food and confirm order</b>
            </h4>

            <h4 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
              Questions asked
            </h4>

            <div
              className="rounded shadow-sm border p-4"
              style={{ marginTop: "2rem", marginBottom: "4rem" }}
            >
              <p> Which parts of the app did you like the most?</p>
              <h3>
                "The process of adding an item to the cart and place the order
                within checkout"
              </h3>
            </div>

            <div
              className="rounded shadow-sm border p-4"
              style={{ marginTop: "2rem", marginBottom: "4rem" }}
            >
              <p>Which parts of the app did you like the least?</p>
              <h3 className="text-danger">
                "I couldn't get any order status, I wanted to track the order
                but couldn't"
              </h3>
            </div>

            <div
              className="rounded shadow-sm border p-4"
              style={{ marginTop: "2rem", marginBottom: "4rem" }}
            >
              <p>What did you think of the interface?</p>
              <h3>
                "It was quite easy, familiar to me. I somehow managed to find my
                item and add that to the cart and eventually, place it."
              </h3>
            </div>

            <div
              className="rounded shadow-sm border p-4"
              style={{ marginTop: "2rem", marginBottom: "4rem" }}
            >
              <p>Could you see yourself using this app regularly?</p>
              <h3>"Yes, ofcourse"</h3>
            </div>
          </div>
        </div>

        {/*UCD aligns with Cost-benefit analysis*/}
        <div className="row" style={{ marginTop: "8rem" }} id="cost-benefit">
          <div className="text-center">
            <h2 style={{ marginTop: "1rem", marginBottom: "10rem" }}>
              Implementation
            </h2>
          </div>
          <div className="col-md-6 align-self-start">
            <img
              src={ninecostanalysis}
              alt="nine-star-cost-benefit-analysis"
              className="w-100"
            />
          </div>
          <div className="col-md-6">
            <p>
              {" "}
              <b>UCD aligning with Cost Benefit Analysis</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              After selecting the UCMD process, I spoke with Nine Star's
              managers and did some analysis on how well the app's feautures can
              be set into the pipeline and what managers estimated the financial
              return for expected features. The Cost-benefit chart boosted my
              progress in comparing the cost of pushing a feature to the value
              it provides which eventually helped to make sustainable design
              decision.
            </h3>
          </div>{" "}
          <div className="col-md-12 text-center" style={{ marginTop: "2rem" }}>
            <h3 style={{ lineHeight: "35px" }}>
              This analysis was performed to make an optimized decision on the
              feature of searching items from the app. To achieve the goal of
              Simplying navigation, this analysis was really useful.
            </h3>
          </div>
        </div>

        {/*Cart to checkout flow - vdo*/}
        <div
          className="row"
          style={{ marginTop: "10rem" }}
          id="checkout-illustration"
        >
          <div className="col-md-4 align-self-center">
            <video
              autoPlay
              loop
              muted
              width="300"
              style={{
                clipPath: "inset(2px 2px)",
              }}
            >
              <source src={ninehomenav} type="video/mp4"></source>
            </video>
          </div>
          <div className="col-md-8">
            <p>
              {" "}
              <b>Search, Navigation Drawer, Filter - How they work</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              As one of the key takeways of CB analysis was to keep the filter
              feature in higher priority and keep the searh by form in medium
              priority, I decided to add these core features as a sticky
              element. Users can find items by filtering or, searching whenever
              they stay in the home page or, the category page.
              <br />
              <br />
              "It was quite easy, familiar to me. I somehow managed to find my
              item and add that to the cart and eventually, place it." - one of
              the participants sent this feedback after using low-fi prototype.
              With that said, no other functionality were accommodated in the
              flow of finding items.
            </h3>
          </div>{" "}
        </div>

        {/*Cart to checkout flow - vdo*/}
        <div
          className="row"
          style={{ marginTop: "10rem" }}
          id="checkout-illustration"
        >
          <div className="col-md-8">
            <p>
              {" "}
              <b>Making the flow easier to learn and less error-prone</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              After adding item to the cart, it is most important not to make
              any impact of Drop-off situation on user's perspective so that
              users can reach to the order confirmation phase simultaneously. To
              enhance conversion, I used{" "}
              <b className="fw-bold">Stage Disclosure</b> scheme under the hood
              which allows users step (one step at a time) through a linear
              sequence of options, with a subset displayed at each step. Here,
              each step is simple and its purpose is clear because features that
              belong to other steps are hidden. To offer maximum effect for
              minimum interactions, this scheme was explicitly helpful.
            </h3>
          </div>{" "}
          <div className="col-md-4 align-self-center">
            <video
              autoPlay
              loop
              muted
              width="300"
              style={{
                clipPath: "inset(2px 2px)",
              }}
            >
              <source src={ninecheckoutvdo} type="video/mp4"></source>
            </video>
          </div>
        </div>

        {/*Deliver - UI showcase*/}
        <div className="row text-center" style={{ marginTop: "12rem" }}>
          <div className="col-md-12">
            <p>
              {" "}
              <b>Deliver - Presenting UI (Mockup)</b>
            </p>
            <h3 style={{ lineHeight: "40px" }}>
              In the home sceen, there are two types of finding items, one is
              searching with text and search by filtering. The rationale I
              demonstrated
              <a href="#cost-benefit"> here </a> already. However, the layout
              used in this screen is very familiar to the users which was
              identified in the usability testing session with low-fidelity
              prototype.
              <br />
              <br />
              To avoid banner blindness, I used offer banner in the middle row
              of dish items so that users can find offers within their thumb
              zone.
            </h3>
          </div>

          <div className="col-md-12 mt-5">
            <img
              src={ninehomecat}
              alt="nine-start-home-category"
              className="w-100"
            />
            <p className="mt-4">Home || Category / Dish </p>
          </div>

          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "40px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              To help users navigate through lots of items, narrowing down the
              list of items to a manageable number was mandatory to make the
              flow of finding items convenient. In the real world shop, items
              are filterd within distinguished self so that customers can find
              items quickly and easily. Thats heuristic pattern is implemented
              in this app. Filtering is the function users will rely on to help
              them navigate their foods and make a decision.
              <br />
              <br />
              To help users find category easily and compare different items
              visually, I used grid list view in the category screen. This view
              allows the items to be kept neat and tidy which attract user's
              focus. Most of the online stores uses grid layout thats the reason
              it has familiarity benefit also.
            </h3>
            <img
              src={ninefiltercatlist}
              alt="nine-star-categorylist-filter"
              className="w-100"
              style={{
                marginBottom: "5rem",
              }}
            />
            <p className="mt-2">Filter || Category list</p>
          </div>

          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "40px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              In order to give short access to the users to find orders,
              payment, shipping and customer support informations, the profile
              screen is designed keep minimality in mind.
              <br />
              <br />
              To ensure continuity and consistency, My list screen is designed
              keeping the goal of this screen in mind, which is allowing users /
              shoppers to create personalized collections of item. Here, My list
              screen also saves a lot of “searching” time on a smaller screen
              whenever users return to the app to purchase the saved item.
            </h3>
            <img
              src={nineprofilewish}
              alt="nine-star-profile-wishlist"
              className="w-100"
            />
            <p className="mt-2">Profile || My list</p>
          </div>

          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "40px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              One of the core goal of a product details page is to allow users
              quickly assess the product. In case of mobile screen, most
              important details need to be placed within effort of zero scroll.
              To provide all required information in the first view, I used
              Stacked Accordion. Using accordion allowed me to reduce cognitive
              load of the users. I used Caret Icon which is the most
              recognizable or expected signifier in accordion functionality
              <br />
              <br />
              For a smooth checkout experience, the cart screen was designed
              providing contextual actions and only showing essential
              information about the item. In the usability testing session, a
              participant accidentally removed an item from the cart, then again
              wanted to add the item. From this task, it became worthy providing
              an undo option for this case. To remind the users that they are
              now in the stage of confirming order, which is crucial is
              enhancing conversion rate, the CTA fill was made change to another
              color from color guide provided by the client.
            </h3>
            <img src={ninepdpcart} alt="nine-star-pdp-cart" className="w-100" />
            <p className="mt-2">Product details || Cart</p>
          </div>

          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "40px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              In the checkout phase, Stage Disclosure scheme was used under the
              hood which has been illustrated{" "}
              <a href="#checkout-illustration">here</a> already. Where the money
              is, is at the checkout line. This is the phase where all the work
              I have put into customer acquisition, education and retention
              actually converts to revenue and ROI. Research shows that more
              than one quarter of users / shoppers abandon an online store
              during the checkout process. Keeping this mind, the page was
              designed giving clarity of item, price and shipping charges
              details to the user, giving user control to edit relevant
              informations, mentioning estimated delivery date. There is also
              the option for "Checkout as a guest" by onboarding into the app
              without giving mail or, phone number (Continue without login).
            </h3>
            <img
              src={ninecheckout1}
              alt="nine-star-checkout-1"
              className="w-100"
            />
            {/*<p className="mt-2">Checkout screens</p>*/}
          </div>

          <div className="col-md-12 mt-5">
            <img
              src={ninecheckout2}
              alt="nine-star-checkout-2"
              className="w-100"
            />

            <p className="mt-2">Checkout screens</p>
          </div>

          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "35px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              In the usability testing session, one of the questions I asked to
              the participants was "Which parts of the app did you like the
              least?". To answer this question, a participant replied, "I
              couldn't get any order status, I wanted to track the order but
              couldn't". To overcome this major pitfall, I designed a dedicated
              screen to show order status allowing users to track their order.
              <br />
              <br />
              The splash screen is designed based on the Aesthetic-Usability
              Effect. An aesthetically pleasing design creates a positive effect
              in people’s brains and leads them to believe the design actually
              works better. There is a neuropsychological theory of positive
              effect which has influence on user's cognition, the theory I tried
              to follow designing the onboarding screens.
            </h3>
            <img
              src={ninetrackonboard}
              alt="nine-star-track-order-onboard"
              className="w-100"
            />
          </div>

          <div className="col-md-12 mt-5">
            <h3
              style={{
                lineHeight: "40px",
                marginTop: "5rem",
                marginBottom: "5rem",
              }}
            >
              As most of the target customers are parents, I tried to figure out
              what their children loved most. Doing a simple research, I found
              that most of the children loved to play{" "}
              <b className="fw-bold">Lego Building Blocks</b> game. So, to make
              the best use of this research, I added a bouncy animation through
              the
              <b className="fw-bold"> Starting Screen</b> of the app, which
              highly likely appears as building something from zero.
            </h3>
            <img
              src={nineanimation}
              alt="nine-star-starting-anim"
              className="w-100"
            />
            <p className="mt-2">
              Flow of the animation used in starting screens
            </p>
          </div>
        </div>

        {/*Last summary */}
        <div
          className="row border rounded p-5 shadow-sm"
          style={{ marginTop: "15rem" }}
        >
          <div className="col-md-12">
            <h3 style={{ lineHeight: "45px" }}>
              Seven Star is now planning to develop their web app. They have
              already gained up to the mark exposure of their app. They are
              collecting user feedback and trying to develop the experience
              more. Fixed customers are working as an evangelist marketer for
              the app.
            </h3>
          </div>
        </div>

        {/*proto-qr*/}
        <div
          style={{
            marginTop: "10rem",
          }}
          className="row"
        >
          <div className="col-md-12 col-12 mb-4">
            <h4
              style={{
                fontSize: "28px",
                //lineHeight: "50px",
                textAlign: "left",
              }}
            >
              Scan this QR code to view prototype in Figma
            </h4>
          </div>
          <div className="col-md-6 col-12 text-left">
            <img src={nineqr} alt="nine-qr" className="w-50" />
            <p className="fw-bold mt-3" style={{ fontSize: "16px" }}>
              Scan Me
            </p>
          </div>
        </div>

        {/*The impact and lesson learnt */}
        <div
          className="d-flex justify-content-center p-3"
          style={{ marginTop: "5rem" }}
        >
          <div className="flex-div border rounded bg-light shadow-sm p-3 me-3">
            <h2 className="text-center mb-4">
              <u>The impact</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Nine star had a huge impact when it first came out. Young
                customers started signingup rapidly caused 100 signups within
                1st 5 hours.
              </li>

              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Nine star owners got two new investors after the app launch.
              </li>
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Strong brand image created througout the local market,
                competitors appreciated the theme of the app.
              </li>
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Most of the children of fixed customers started requesting to
                their parents to order food from Nine star just to see the
                starting screen repeatedly.
              </li>
            </ul>
          </div>

          <div className="flex-div border rounded shadow-sm p-3">
            <h2 className="text-center mb-4">
              <u>Lesson learnt</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                SWOT analysis helped to conduct competitive audit in a more
                efficient way
              </li>

              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Checkout process is the most highly scrutinized parts of the
                user journey.
              </li>
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Making registration and login less painful on mobile is the key.
              </li>
            </ul>
          </div>
        </div>

        <p className="text-center" style={{ marginTop: "5rem" }}>
          - A big thank you for the time you spent reading this case study -
        </p>
      </div>
    ),
  },
];

export function getProjectSixthData() {
  return ProjectSixthData;
}
